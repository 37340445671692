<template>
  <div class="termService">
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col cols="12" class="title-section">
          <h2>เงื่อนไขการใช้บริการ</h2>
        </v-col>
        <v-col cols="12" class="body-section">
          <p>1. ทางผู้พัฒนาเป็นเพียงผู้อำนวยความสะดวกในการเข้าถึง blockchain ได้โดยง่าย ไม่ได้มีส่วนเกี่ยวข้องในการเก็บรักษาทรัพย์สินดิจิตอลแต่อย่างใด</p>
          <p>2. ผู้ใช้จำเป็นต้องรักษาและจดจำ Private Key และจดจำ pin code ด้วยตัวเอง หากทำหาย ถูกขโมย หรือจำไม่ได้ ทางผู้พัฒนาไม่สามารถ
          นำกลับคืนมาได้ รวมถึงทรัพย์สินดิจิตอลทั้งหมดใน Private  key นั้นอาจสูญหายทั้งหมด</p>
          <p>3. การโอนหรือรับโทเค็นจากผู้อื่น เกิดขึ้นได้จากผู้ใช้เท่านั้น ดังนั้นโปรดตรวจสอบ address ผู้รับโอนให้ดีด้วยตัวเองก่อนทุกครั้ง
          หากเกิดการผิดพลาดเนื่องจากปัญหาอื่นใด ไมได้เป็นความรับผิดชอบของผู้พัฒนา</p>
          <p>4. สินทรัพย์ดิจิตอลบางชนิด มีการหมดอายุ จะสามารถหายไปเองได้ทันทีเมื่อถึงกำหนดเวลา เช่น เหรียญ MD</p>
        </v-col>
        <v-col cols="12" class="button-section">
          <v-btn
            block
            rounded
            dark
            dense
            height="49"
            color="#f47c94"
            @click="$router.push('/OTP/emailVerify')"
            >ยอมรับเงื่อนไข</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TermService",
};
</script>

<style lang="scss">
.termService {
  height: 100vh;
  padding: 0 16px;
  max-width: 700px;
  margin: 0 auto;
  .title-section {
    height: 100px;
    font-size: 21px;
    display: flex;
    align-items: center;
  }
  .body-section {
    height: calc(100vh - 180px);
    overflow-y: scroll;
    font-size: 14px;
  }
  .button-section {
    margin: 0 auto;
    height: 80px;
    display: flex;
    max-width: 350px;
    align-items: center;
  }
}
</style>